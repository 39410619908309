import {
  Scene,
  WebGLRenderer,
  WebGLRenderTarget,
  Color,
  SRGBColorSpace,
  OrthographicCamera,
  LinearFilter,
  RGBAFormat,
  Vector2,
  Vector3,
  PlaneGeometry,
  ShaderMaterial,
  DoubleSide,
  Mesh,
  TextureLoader,
  MeshBasicMaterial,
  AdditiveBlending
} from 'three';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import fragment from '../shaders/fragment.glsl';
import vertex from '../shaders/vertex.glsl';
import brush from '../../images/brush.png';

export default class BackgroundTexture {
  constructor(options) {
    this.scene1 = new Scene();
    this.scene2 = new Scene();
    this.container = options.element;
    this.width = window.innerWidth < 768 ? 768 : window.innerWidth; // Set a min width
    this.height = window.innerHeight;
    this.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(new Color(0x000000), 1);
    this.renderer.outputColorSpace = SRGBColorSpace;

    // Make the camera full screen
    const frustumSize = this.height;
    const aspect = this.width / this.height;
    this.camera = new OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      -1000,
      1000
    );
    this.camera.position.set(0, 0, 2);

    this.baseTexture = new WebGLRenderTarget(this.width, this.height, {
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      format: RGBAFormat
    });

    this.time = 0;
    this.mouse = new Vector2(0, 0);
    this.prevMouse = new Vector2(0, 0);
    this.currentWave = 0;

    // Texture Colours
    // https://airtightinteractive.com/util/hex-to-glsl/
    this.yellow = new Vector3(0.882, 0.988, 0.369);
    this.grey = new Vector3(0.918, 0.91, 0.925);
    this.blue = new Vector3(0.106, 0.125, 0.161);
    this.black = new Vector3(0.0, 0.0, 0.0);

    this.mouseEvents();
    this.addObjects();
    this.resize();
    this.setupResize();
    this.setupScrollTrigger();
  }

  setupResize() {
    window.addEventListener('resize', this.resize.bind(this));
  }

  resize() {
    this.width = window.innerWidth < 768 ? 768 : window.innerWidth;
    this.height = window.innerHeight;
    this.renderer.setSize(this.width, this.height);
  }

  mouseEvents() {
    window.addEventListener('mousemove', (event) => {
      this.mouse.x = event.clientX - this.width / 2;
      this.mouse.y = this.height / 2 - event.clientY;
    });

    window.addEventListener('touchmove', (event) => {
      this.mouse.x = event.touches[0].clientX - this.width / 2;
      this.mouse.y = this.height / 2 - event.touches[0].clientY;
    });
  }

  addObjects() {
    this.marbleGeom = new PlaneGeometry(this.width, this.height);
    this.marbleMat = new ShaderMaterial({
      side: DoubleSide,
      uniforms: {
        time: { value: 0 },
        uDisplacement: { value: null },
        color1: { value: this.yellow },
        color2: { value: this.grey }
      },
      vertexShader: vertex,
      fragmentShader: fragment
    });
    this.marbleMesh = new Mesh(this.marbleGeom, this.marbleMat);
    this.scene1.add(this.marbleMesh);

    this.maxWaves = 50;
    this.meshes = [];

    this.brushGeom = new PlaneGeometry(100, 100);
    this.brushTexture = new TextureLoader().load(brush, createBrushes.bind(this));

    function createBrushes() {
      for (let i = 0; i < this.maxWaves; i++) {
        let brushMat = new MeshBasicMaterial({
          map: this.brushTexture,
          transparent: true,
          blending: AdditiveBlending,
          depthTest: false,
          depthWrite: false
        });
        let mesh = new Mesh(this.brushGeom, brushMat);
        mesh.visible = false;
        mesh.rotation.z = 2 * Math.PI * Math.random();
        this.scene2.add(mesh);
        this.meshes.push(mesh);
      }

    // ! Ensure renderer doesn't run until meshes exist
    this.renderer.setAnimationLoop(this.animation.bind(this));
    this.container.appendChild(this.renderer.domElement);
    this.container.classList.add('rendered');
    }
  }

  setNewWave(x, y, index) {
    let mesh = this.meshes[index];
    mesh.scale.x = mesh.scale.y = 1;
    mesh.material.opacity = 1;
    mesh.visible = true;
    mesh.position.x = x;
    mesh.position.y = y;
  }

  trackMousePosition() {
    if (
      Math.abs(this.mouse.x - this.prevMouse.x) < 4 &&
      Math.abs(this.mouse.y - this.prevMouse.y) < 4
    ) {
      return;
    } else {
      this.setNewWave(this.mouse.x, this.mouse.y, this.currentWave);
      this.currentWave = (this.currentWave + 1) % this.maxWaves;
    }

    this.prevMouse.x = this.mouse.x;
    this.prevMouse.y = this.mouse.y;
  }

  setupScrollTrigger() {
    let lightToDark = gsap.timeline({
      scrollTrigger: {
        trigger: '.intro',
        toggleActions: 'play none reverse none',
        start: 'center center',
        end: 'center center'
      }
    });

    lightToDark
      .addLabel('start')
      .to(this.marbleMat.uniforms.color1.value, {
        x: this.black.x,
        y: this.black.y,
        z: this.black.z,
        duration: 0.2
      }, 'start')
      .to(this.marbleMat.uniforms.color2.value, {
        x: this.blue.x,
        y: this.blue.y,
        z: this.blue.z,
        duration: 0.2
      }, 'start');
  }

  animation() {
    this.trackMousePosition();
    this.time += 0.0005;
    this.marbleMat.uniforms.time.value = this.time;

    this.renderer.setRenderTarget(this.baseTexture);
    this.renderer.render(this.scene2, this.camera);
    this.marbleMat.uniforms.uDisplacement.value = this.baseTexture.texture;
    this.renderer.setRenderTarget(null);
    this.renderer.clear();
    this.renderer.render(this.scene1, this.camera);

    this.meshes.forEach((mesh) => {
      if (mesh.visible) {
        mesh.rotation.z += 0.1;
        mesh.scale.x = 0.984 * mesh.scale.x + 0.107;
        mesh.scale.y = mesh.scale.x;
        mesh.material.opacity *= 0.95;
        if (mesh.material.opacity < 0.002) {
          mesh.visible = false;
        }
      }
    });
  }
}
