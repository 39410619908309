import { gsap } from 'gsap';
import axios from 'axios';

const body = document.querySelector('body');
const modalRoot = document.querySelector('#js-modal');
const modalOverlay = document.querySelector('#js-modal-overlay');
const modalContainer = modalRoot.querySelector('#js-modal-container');
const modalContent = modalRoot.querySelector('#js-modal-content');


// We'll store properties in these
const modal = {};
const question = {};

function handleClick(event) {
  const openLink = event.target.closest('.js-modal-open');
  const closeLink = event.target.closest('.js-modal-close');
  const modalContainer = event.target.closest('#js-modal-container');

  if (openLink) {
    event.preventDefault();
    openModal(openLink);
    question.link = openLink;
    return;
  }

  if (closeLink) {
    event.preventDefault();
    closeModal();
    return;
  }

  // If modal is open, and user clicks outside the modal container, close it.
  if (body.classList.contains('modal-is-active') && !modalContainer) {
    event.preventDefault();
    closeModal(false);
    return;
  }
}

// Helper function to switch background and border colours
function copyColors(target, original) {
  target.style.backgroundColor =
    getComputedStyle(original).getPropertyValue('background-color');
  target.style.borderColor =
    getComputedStyle(original).getPropertyValue('border-color');
}

function openModal(link) {
  // Add class to body to prevent scrolling
  // And unhide modal elements
  body.classList.add('modal-is-active');
  modalRoot.setAttribute('aria-hidden', 'false');

  // Store natural modal container position (this is the end state)
  modal.position = modalContainer.getBoundingClientRect();

  // Get the position of the question element that's been clicked (this will be the starting state)
  question.position = link.getBoundingClientRect();

  // Make the modal container fixed
  // And set its starting position to match the question
  modalContainer.style.position = 'fixed';
  modalContainer.style.top = `${question.position.top}px`;
  modalContainer.style.left = `${question.position.left}px`;
  modalContainer.style.width = `${question.position.width}px`;
  modalContainer.style.height = `${question.position.height}px`;

  // Set the colours to match the question element;
  // CSS transition makes this smooth
  copyColors(modalContainer, link);

  // Make modal transparent initially before we animate it in
  modalContainer.style.opacity = 0;
  // Add class that sets border-radius
  modalContainer.classList.add('is-visible');

  // Animate modal opening
  // - Fade in modal overlay
  // - Fade in modal container over question
  // - Move the modal container to right position
  // - Then load the modal content
  gsap.timeline()
    .addLabel('crossfade')
    .to(modalOverlay, {
      opacity: 1,
      duration: 0.3
    }, 'crossfade')
    .to(modalContainer, {
      opacity: 1,
      duration: 0.3
    }, 'crossfade')
    .to(modalContainer, {
      top: `${modal.position.top}px`,
      left: `${modal.position.left}px`,
      width: `${modal.position.width}px`,
      // Height is set to 100% to appear to fill the screen
      // We'll clear this when the content is loaded so you can scroll properly
      height: '100%',
      duration: 0.5,
      onComplete: () => {
        loadModal(link.href);
        // Shift keyboard focus to modal
        modalRoot.focus();
      }
    });
  // Add scroll event listener to the modal container
  modalContainer.addEventListener('scroll', handleModalScroll);
}

function loadModal(url) {
  axios
    .get(url)
    .then((response) => {
      processModalAjax(response);
    })
    // TODO: Display a message to the user if there's an error
    .catch((error) => console.log(error));
}

function processModalAjax(response) {
  const fragment = document
    .createRange()
    .createContextualFragment(response.data);
  const content = fragment.querySelector('#question-content');
  content.style.opacity = 0;
  modalContent.appendChild(content);
  // Clear the inline styles on the modal container so user can scroll it
  gsap.set(modalContainer, { clearProps: true });
  // Fade the content in
  gsap.to(content, {
    opacity: 1,
    duration: 0.3,
    delay: 0.3 // Wait for the background colour to change first
  });
}

function closeModal() {
  // Update the positions based on where they are now
  modal.position = modalContainer.getBoundingClientRect();
  question.position = question.link.getBoundingClientRect();

  // Make the modal container fixed in place again so we can animate it
  modalContainer.style.position = 'fixed';
  modalContainer.style.top = `${modal.position.top}px`;
  modalContainer.style.left = `${modal.position.left}px`;
  modalContainer.style.width = `${modal.position.width}px`;
  modalContainer.style.height = `${modal.position.height}px`;

  // Animate modal closing
  // - Fade out the content
  // - Move it back where the original question element is
  // - Fade out the overlay
  // - Fade out the modal container
  // - Reset everything
  gsap
    .timeline()
    .to('#question-content', {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        // Set the colours to match the question element
        copyColors(modalContainer, question.link);
        // Remove border-radius override
        modalContainer.classList.remove('is-visible');
      }
    })
    .to(modalContainer, {
      top: `${question.position.top}px`,
      left: `${question.position.left}px`,
      width: `${question.position.width}px`,
      height: `${question.position.height}px`,
      duration: 0.5
    })
    .addLabel('crossfade')
    .to(modalOverlay, {
      opacity: 0,
      duration: 0.3
    }, 'crossfade')
    .to(modalContainer, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        // Clear the content container
        modalContent.innerHTML = '';
        // Hide all the modal elements again
        body.classList.remove('modal-is-active');
        modalRoot.setAttribute('aria-hidden', 'true');
        // Remove inline styles
        gsap.set(modalContainer, { clearProps: true });
        // Move focus back to the original question
        question.link.focus();
      }
    }, 'crossfade');
  // Remove scroll event listener from modal container
  modalContainer.removeEventListener('scroll', handleModalScroll);
}

function handleModalScroll() {
  // Show the close element when the modal is scrolled
  const closeElement = document.getElementById('js-modal-close');
  closeElement.style.display = 'block';
  console.log(closeElement);
}

export default function () {
  document.addEventListener('click', handleClick);
}
