const tabs = document.querySelectorAll('[data-tab-value]');
const tabsInfos = document.querySelectorAll('[data-tab-info]');

tabs.forEach(tab => {
  tab.addEventListener('click', () => {
    const target = document.querySelector(tab.dataset.tabValue);

    tabsInfos.forEach(tabInfo => {
      tabInfo.classList.remove('library__active-link');
    })

    tabs.forEach(tab => {
      tab.classList.remove('library__active-tab');
    })
    
    target.classList.add('library__active-link');
    tab.classList.add('library__active-tab');
  });
});