
import nav from './modules/nav';
import BackgroundTexture from './modules/background';
import burgerMenu from './modules/burger-menu';
import modal from './modules/modal';
import library from './modules/library';


function init() {
  // Setup background
  new BackgroundTexture({
    element: document.getElementById('js-bg-texture')
  });

  // Init other modules:
  modal();
}

if (document.readyState === 'loading') {
  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', init);
} else {
  // `DOMContentLoaded` has already fired
  init();
}
